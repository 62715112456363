function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
var BRAND = 'brand';
var MARKET = 'market';
var VERSION = 'version';
var getParameters = function getParameters() {
  var params = {};
  var url = window.location.href;
  var paramString = url.substr(url.indexOf('?') + 1);
  var paramPairs = paramString.split('&');
  paramPairs.forEach(function (paramPair) {
    var _paramPair$split = paramPair.split('='),
      _paramPair$split2 = _slicedToArray(_paramPair$split, 2),
      key = _paramPair$split2[0],
      value = _paramPair$split2[1];
    params[key] = decodeURIComponent(value);
  });
  return params;
};
var getRequiredParams = function getRequiredParams(params) {
  return "\n  <li>Brand: ".concat(params[BRAND] || "No ".concat(BRAND, " detected."), "</li>\n  <li>Market: ").concat(params[MARKET] || "No ".concat(MARKET, " detected."), "</li>\n  <li>Version: ").concat(params[VERSION] || "No ".concat(VERSION, " detected."), "</li>\n  ");
};
var getAdditionalParams = function getAdditionalParams(params) {
  var output = '';
  Object.keys(params).forEach(function (key) {
    if (key !== MARKET && key !== BRAND && key !== VERSION) {
      output = "".concat(output, "<li>").concat(key, ": ").concat(params[key], "</li>");
    }
  });
  return output;
};
var isValidCombination = function isValidCombination(params) {
  var combinations = ["brand=oticon&market=en-gb&version=20.2", "brand=oticon&market=pt-br&version=20.2", "brand=oticon&market=de-de&version=20.2", "brand=oticon&market=da-dk&version=20.2", "brand=oticon&market=es-es&version=20.2", "brand=oticon&market=fi-fi&version=20.2", "brand=oticon&market=fr-fr&version=20.2", "brand=oticon&market=it-it&version=20.2", "brand=oticon&market=ja-jp&version=20.2", "brand=oticon&market=nl-nl&version=20.2", "brand=oticon&market=nb-no&version=20.2", "brand=oticon&market=pl-pl&version=20.2", "brand=oticon&market=cs-cz&version=20.2", "brand=oticon&market=ru-ru&version=20.2", "brand=oticon&market=sv-se&version=20.2", "brand=oticon&market=tr-tr&version=20.2", "brand=oticon&market=en-us&version=20.2", "brand=oticon&market=en-gb&version=20.2", "brand=oticon&market=en-gb&version=20.2", "brand=oticon&market=en-gb&version=20.2", "brand=oticon&market=hu-hu&version=20.2", "brand=nhs&market=en-gb&version=20.2", "brand=oticon&market=en-us&version=20.2", "brand=audika&market=en&version=20.2", "brand=audika&market=fr-fr&version=20.2", "brand=audigy&market=en-us&version=20.2", "brand=kind&market=en-gb&version=20.2", "brand=kind&market=pl-pl&version=20.2", "brand=kind&market=de-de&version=20.2", "brand=amplifon&market=en&version=20.2", "brand=amplifon&market=it-it&version=20.2", "brand=amplifon&market=de-de&version=20.2", "brand=amplifon&market=fr-fr&version=20.2", "brand=amplifon&market=nl-nl&version=20.2"];
  return combinations.indexOf("brand=".concat(params[BRAND], "&market=").concat(params[MARKET], "&version=").concat(params[VERSION])) > -1;
};
var richText = document.querySelector('.content .rich-text');
var params = getParameters();
if (params[VERSION] === '21.1') {
  if (richText) {
    if (isValidCombination(params)) {
      richText.innerHTML = "<h1>Genie resource center query check</h1><div style=\"color: green;\">Combination of brand, market and version parameters are valid</div><h2>Required parameters</h2><ul>".concat(getRequiredParams(params), "</ul><h2>Redundant parameters</h2><ul>").concat(getAdditionalParams(params), "</ul>");
    } else {
      richText.innerHTML = "<h1>Genie resource center query check</h1><div style=\"color: red;\">Combination of brand, market and version parameters are NOT valid</div><h2>Required parameters</h2><ul>".concat(getRequiredParams(params), "</ul><h2>Redundant parameters</h2><ul>").concat(getAdditionalParams(params), "</ul>");
    }
  }
}