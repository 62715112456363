var headerSection = document.querySelector('.two-section-grid .section-one');
console.log({
  headerSection: headerSection
});
var brand = getParameterByName('brand') || 'oticon';
if (headerSection) {
  headerSection.innerHTML = "<div class=\"dp-resource-center ".concat(brand.toLowerCase(), "\">\n  <div class=\"header__logo__container\"><div class=\"header__logo\"></div></div>\n  </div>");
}
function getParameterByName(name) {
  var url = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}